<!--  -->
<template>
  <div class="datetimepicker">
    <el-select
      v-model="selectt"
      @change="dateTypeSelectChange"
      :placeholder="$t('aboutus.7EF062@Default')"
      style="width:100px;"
      class="mr10"
      clearable
    >
      <el-option :label="$t('aboutus.AB78A8@Day')" value="1"></el-option>
      <el-option :label="$t('aboutus.420F01@month')" value="2"></el-option>
      <el-option :label="$t('aboutus.480440@season')" value="3"></el-option>
      <el-option :label="$t('aboutus.B52CFC@year')" value="4"></el-option>
    </el-select>
    <!-- 日 -->
    <el-date-picker
      v-if="selectt === '1'"
      value-format="timestamp"
      v-model="listQuery.day"
      @change="dayChange"
      type="daterange"
      :picker-options="dayDateOption"
      :default-time="['00:00:00', '23:59:59']"
      style="width:220px"
    ></el-date-picker>
    <!-- 月 -->
    <el-date-picker
      v-if="selectt === '2'"
      v-model="listQuery.month"
      type="monthrange"
      value-format="timestamp"
      :picker-options="monthDateOption"
      @change="monthChange"
      style="width:220px"
    ></el-date-picker>

    <!--季度时间选择控件 -->
    <span v-if="selectt === '3'">
      <jidu-date-picker ref="jidupicker" @chooseSeason="seasonChange"></jidu-date-picker>&nbsp; -&nbsp;
      <jidu-date-picker ref="jidupicker" @chooseSeason="seasonChange1"></jidu-date-picker>
    </span>

    <!-- 年 -->
    <section v-if="selectt === '4'">
      <el-date-picker
        v-model="listQuery.year[0]"
        type="year"
        value-format="timestamp"
        :picker-options="yearDateOptions"
        @change="yearChange"
        style="width:120px"
      ></el-date-picker>&nbsp; -&nbsp;
      <el-date-picker
        v-model="listQuery.year[1]"
        type="year"
        value-format="timestamp"
        @change="yearChange"
        :picker-options="yearDateOptions"
        style="width:120px"
      ></el-date-picker>
    </section>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import jiduDatePicker from "./quarterPicker";
export default {
  //import引入的组件需要注入到对象中才能使用
  name: "datetimepicker",
  components: {
    jiduDatePicker
  },
  data() {
    //这里存放数据
    return {
      selectt: "",
      listQuery: {
        day: "",
        month: "",
        season: [],
        year: [],
        pickerMinDate: ""
      },
      monthDateOption: {
        disabledDate(time) {
          return (
            time.getFullYear() > new Date().getFullYear() ||
            (time.getFullYear() == new Date().getFullYear() &&
              time.getMonth() + 1 > new Date().getMonth() + 1)
          );
        }
      },
      yearDateOptions: {
        disabledDate: time => {
          return time.getFullYear() > new Date().getFullYear();
        }
      },
      dayDateOption: {
        //   跨度限制60天
        onPick: obj => {
          console.log(obj);
          this.pickerMinDate = new Date(obj.minDate).getTime();
        },
        disabledDate: time => {
           let date = new Date();
          let str =
            date.getFullYear() +
            "/" +
            (date.getMonth() + 1) +
            "/" +
            date.getDate();
          if (this.pickerMinDate) {
            const day1 = 60 * 24 * 3600 * 1000;
            let maxTime = this.pickerMinDate + day1;
            let minTime = this.pickerMinDate - day1;
            return (
              time.getTime() > maxTime ||
              time.getTime() < minTime ||
              Date.parse(time) / 1000 >=
                Date.parse(str) / 1000
            );
          }
        }
      }
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //   获取选中的最后一个月的最后一天
    morenDate(val, type) {
      let date = new Date(val);
      console.log(date);

      let nowMonth = date.getMonth() + 1;
      let month;
      if (type) {
        month = date.getMonth() + 3;
      } else {
        month = date.getMonth() + 1;
      }
      if (month < 10) {
        month = "0" + month;
      }
      let starttime = date.getFullYear() + "-" + nowMonth + "-01 00:00:00";
      let endtime = null;
      if (month == "02") {
        endtime = date.getFullYear() + "-" + month + "-28 23:59:59";
      }
      if (
        month == "01" ||
        month == "03" ||
        month == "05" ||
        month == "07" ||
        month == "08" ||
        month == "10" ||
        month == "12"
      ) {
        endtime = date.getFullYear() + "-" + month + "-31 23:59:59";
      }
      if (month == "04" || month == "06" || month == "09" || month == "11") {
        endtime = date.getFullYear() + "-" + month + "-30 23:59:59";
      }
      if (type) {
        return Date.parse(endtime);
      } else {
        return Date.parse(endtime);
      }
    },
    // 选择时间类型下拉
    dateTypeSelectChange(val) {
      this.$emit("dateTypeSelectChange", val);
      this.listQuery = {
        day: "",
        month: "",
        season: [],
        year: [],
        pickerMinDate: ""
      };
    },
    // 选择日
    dayChange(val) {
      this.$emit("datetimeChange", val);
    },
    // 选择月
    monthChange(val) {
      this.$emit(
        "datetimeChange",
        val ? [val[0], this.morenDate(val[1], 0)] : []
      );
    },
    // 选择季度
    seasonChange(val) {
      this.listQuery.season[0] = Date.parse(val);
      if (this.listQuery.season[0] && this.listQuery.season[1]) {
        this.$emit("datetimeChange", this.listQuery.season);
      }
    },
    // 选择季度
    seasonChange1(val) {
      this.listQuery.season[1] = this.morenDate(val, 1);
      if (this.listQuery.season[0] && this.listQuery.season[1]) {
        this.$emit("datetimeChange", this.listQuery.season);
      }
    },
    // 选择年
    yearChange(val) {
      if (this.listQuery.year[0] && this.listQuery.year[1]) {
        this.listQuery.year[1] = Date.parse(
          new Date(this.listQuery.year[1]).getFullYear() +
            "-" +
            "12-31 23:59:59"
        );
        this.$emit("datetimeChange", this.listQuery.year);
      }
    },
    reset() {
      this.selectt = "3";
      this.timereset();
    },
    getDate(time) {
      // 时间格式转化 年-月-日
      var date = new Date(time);
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    getBeforeMonth() {
      // 获取上个月时间  年-月-日
      var seperator1 = "-";
      var day = "01";
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      if (month === 1) {
        year -= 1;
        month = 12;
      } else {
        month -= 1;
      }
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      var currentdate = year + seperator1 + month + seperator1 + day;
      return currentdate;
    },
    getBeforeSeason() {
      // 获取上一季度
      const year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;
      if (month <= 3) {
        year -= 1;
        return `${year}-10-01`;
      } else if (month > 3 && month <= 6) {
        return `${year}-01-01`;
      } else if (month > 6 && month <= 9) {
        return `${year}-04-01`;
      } else if (month > 9 && month <= 12) {
        return `${year}-07-01`;
      }
    },
    getBeforeYear() {
      // 获取上一年
      const year = new Date().getFullYear() - 1;
      return `${year}-01-01`;
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' >
//@import url(); 引入公共css类
.datetimepicker {
  display: flex;
  align-items: center;
}
</style>