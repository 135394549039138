<template>
  <div :style="{height:height,width:width}" />
</template>

<script>
import echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
import resize from "./mixins/resize";
function formatNum(strNum) {
  if (strNum.length <= 3) {
    return strNum;
  }
  if (!/^(\+|-)?(\d+)(\.\d+)?$/.test(strNum)) {
    return strNum;
  }
  var a = RegExp.$1;
  var b = RegExp.$2;
  var c = RegExp.$3;
  var re = new RegExp();
  re.compile("(\\d)(\\d{3})(,|$)");
  while (re.test(b)) {
    b = b.replace(re, "$1,$2$3");
  }
  return a + "" + b + "" + c;
}
export default {
  mixins: [resize],
  props: {
    title: {
      type: String,
      default: ""
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "400px"
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    xAxis: {
      type: Array,
      required: true
    },
    chartData: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      chart: null
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val);
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      this.setOptions(this.chartData);
    },
    setOptions() {
      this.chart.setOption({
        // title: {
        //   text: this.title,
        //   left: '50%',
        //   top: 20
        // },
        xAxis: {
          name: "Date",
          nameLocation: "middle",
          nameTextStyle: {
            fontSize: 14,
            fontWeight: 600
          },
          nameGap: 25,
          data: this.xAxis,
          boundaryGap: false,
          rotate: -15
        },
        yAxis: {
          name: "Amount(HK$)",
          nameLocation: "end",
          nameTextStyle: {
            fontSize: 14,
            fontWeight: 600
          },
          nameRotate: 0,
          nameGap: 10,
          axisTick: {
            show: false //坐标轴上的小短线
          }
        },
        grid: {
          //图表的高低大小样式
          left: 10,
          right: 10,
          bottom: 20,
          top: 30,
          containLabel: true
        },
        tooltip: {
          //悬浮时的样式
          trigger: "axis",
          axisPointer: {
            type: "cross"
          },
          padding: [10, 10]
        },
        legend: {
          show: true,
          right: 0,
          top: 0
        },
        series: [
          {
            type: "line",
            label: {
              show: true,
              rotate: 45,
              formatter: function(p) {
                return formatNum(p.value);
              }
            },
            data: this.chartData
          }
        ]
      });
    }
  }
};
</script>
