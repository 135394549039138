<template>
  <div class="dashboard-container">
    <page-top :title="$t('aboutus.F12209@analysis')">
      <span class="fa fa-line-chart fa-2x"></span>
    </page-top>
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane name="1" :label="$t('aboutus.5F8C9F@Purchasing_Price')">
        <div class="mb20">
          <datetimePicker
            @dateTypeSelectChange="getDate"
            @datetimeChange="dateRange"
          />
        </div>
        <line-chart v-if="activeName == '1'" :xAxis="lineX" :chart-data="lineData"></line-chart>
      </el-tab-pane>
      <el-tab-pane name="2" :label="$t('aboutus.DD54D2@Product_Chart')">
        <el-select v-model="chartType" style="width:300px;margin-bottom:20px;">
          <el-option
            :value="1"
            :label="$t('aboutus.95A642@Products_In_Total_Amount')"
          ></el-option>
          <el-option
            :value="2"
            :label="$t('aboutus.C991DB@Receive_Top_Ten_Products')"
          ></el-option>
          <el-option
            :value="3"
            :label="$t('aboutus.0026F6@Purchase_Amount')"
          ></el-option>
          <el-option :value="4" :label="$t('aboutus.74A5F4@sum')"></el-option>
        </el-select>
        <bar-chart
          v-if="activeName == '2'"
          :yAxis="
            chartType == 1
              ? barY1
              : chartType == 2
              ? barY2
              : chartType == 3
              ? barY3
              : barY4
          "
          :chart-data="
            chartType == 1
              ? barData1
              : chartType == 2
              ? barData2
              : chartType == 3
              ? barData3
              : barData4
          "
        ></bar-chart>
      </el-tab-pane>
      <el-tab-pane name="3" :label="$t('aboutus.A446F9@Product_changes')">
        
        <section>
          <h5
            style="margin-bottom:20px;font-weight:500;"
          >{{ $t('aboutus.9E51D1@Order_item_amount_difference') }}&nbsp;:</h5>
          <el-date-picker
            class="mb20"
            v-model="timeRange1"
            @change="timeRangeFunc1"
            type="daterange"
            range-separator="~"
            :start-placeholder="$t('placeholder.524620@start_time')"
              :end-placeholder="$t('placeholder.ABB7CF@end_time')"
          >
          </el-date-picker>
            <!-- :row-class-name="$tableRowClassName" -->
          <el-table
            border
            :data="tableData1"
            style="width: 100%"
          >
            <el-table-column
              prop="order_no"
              :label="$t('placeholder.5C894A@order_code')"
              sortable
              align="center"
            ></el-table-column>
            <el-table-column
              prop="goods_name"
              :label="$t('restaurant_inventory.0009CF@product_name')"
              sortable
              align="center"
            ></el-table-column>
            <el-table-column
              prop="unit_name"
              :label="
                $t(
                  'supplier-produceManage-publishProduct.4E6A29@Commodity_unit'
                )
              "
              sortable
              align="center"
            >
              <template slot-scope="scope">
                <span>{{scope.row.unit_num | formatNum}} /{{scope.row.unit_name}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="origin_unit_price"
              :label="$t('aboutus.6A7E47@initial_unit')"
              :sortable="true"
              align="center"
            >
              <template slot-scope="scope">
                <span>${{scope.row.origin_unit_price | formatNum}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="origin_unit_price"
              :label="$t('aboutus.2DF5D9@pay_unit')"
              :sortable="true"
              align="center"
            >
              <template slot-scope="scope">
                <span>${{scope.row.origin_unit_price | formatNum}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="create_time"
              :label="$t('aboutus.B0F833@order_time')"
              sortable
              align="center"
            ></el-table-column>
            <el-table-column
              prop='price_diff'
              :label="$t('aboutus.0390DF@difference')"
              :sortable="true"
              align="center"
            >
              <template slot-scope="scope">
                <span style="color:#67C23A;" v-if="scope.row.price_diff_symbol == '+'">{{'+' + scope.row.price_diff | formatNum}}</span>
                <span style="color:#F56C6C;" v-else>{{'-' + scope.row.price_diff | formatNum}}</span>
              </template>
            </el-table-column>
          </el-table>
          <section class="mt20 flex flex-end">
            <el-pagination
              :page-sizes="[5]"
              :page-size="diff1.size"
              @current-change="handleCurrentChange1"
              :current-page="diff1.page"
              layout="total,prev, pager, next, jumper"
              :total="total1"
            ></el-pagination>
          </section>
        </section>
        <section>
          <h5
            style="margin-bottom:20px;font-weight:500;"
          >{{ $t('aboutus.412295@Order_quantity_difference') }}&nbsp;:</h5>
          <el-date-picker
            class="mb20"
            v-model="timeRange2"
            @change="timeRangeFunc2"
            type="daterange"
            range-separator="~"
            :start-placeholder="$t('placeholder.524620@start_time')"
              :end-placeholder="$t('placeholder.ABB7CF@end_time')"
          ></el-date-picker>
            <!-- :row-class-name="$tableRowClassName" -->
          <el-table
            border
            :data="tableData2"
            style="width: 100%"
          >
            <el-table-column
              prop="order_no"
              :label="$t('supplier_order.46C89C@order_reference')"
              sortable
              align="center"
            ></el-table-column>
            <el-table-column
              prop="goods_name"
              :label="$t('restaurant_supplier_products.CE8240@product_name')"
              sortable
              align="center"
            ></el-table-column>
            <el-table-column
              prop="unit_name"
              :label="
                $t(
                  'supplier-produceManage-publishProduct.4E6A29@Commodity_unit'
                )
              "
              sortable
              align="center"
            >
              <template slot-scope="scope">
                <span>{{scope.row.unit_num | formatNum}} /{{scope.row.unit_name}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="origin_buy_num"
              :label="$t('aboutus.E3A644@Order_quantity')"
              :sortable="true"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{scope.row.origin_buy_num | formatNum}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="receive_num"
              :label="$t('orderDetail.3BB7C2@Accept_number')"
              :sortable="true"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{scope.row.receive_num | formatNum}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="create_time"
              :label="$t('aboutus.B0F833@order_time')"
              sortable
              align="center"
            ></el-table-column>
            <el-table-column
              prop="origin_buy_num"
              :label="$t('aboutus.0390DF@difference')"
              :sortable="true"
              align="center"
            >
              <template slot-scope="scope">
                <span style="color:#67C23A;" v-if="scope.row.origin_buy_num == '+'">{{'+' + scope.row.num_diff | formatNum}}</span>
                <span style="color:#F56C6C;" v-else>{{'-' + scope.row.num_diff | formatNum}}</span>
              </template>
            </el-table-column>
          </el-table>
          <section class="mt20 flex flex-end">
            <el-pagination
              :page-sizes="[5]"
              :page-size="diff2.size"
              @current-change="handleCurrentChange2"
              :current-page="diff2.page"
              layout="total,prev, pager, next, jumper"
              :total="total2"
            ></el-pagination>
          </section>
        </section>

      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import pageTop from "@/components/PageTop/PageTop";
import BarChart from "@/components/Charts/BarChart";
import lineChart from "@/components/Charts/LineChart";
import quarterPicker from "./components/quarterPicker";
import datetimePicker from "./components/datetimePicker";

export default {
  name: "Dashboard",
  components: { pageTop, BarChart, lineChart, quarterPicker, datetimePicker },
  filters:{
    formatNum(strNum) {
      if (strNum.length <= 3) {
        return strNum;
      }
      if (!/^(\+|-)?(\d+)(\.\d+)?$/.test(strNum)) {
        return strNum;
      }
      var a = RegExp.$1;
      var b = RegExp.$2;
      var c = RegExp.$3;
      var re = new RegExp();
      re.compile("(\\d)(\\d{3})(,|$)");
      while (re.test(b)) {
        b = b.replace(re, "$1,$2$3");
      }
      return a + "" + b + "" + c;
    }
  },
  data() {
    return {
      activeName: "1",

      // 总销售额
      line: {
        type: 0,
        start_time: 0,
        end_time: 0
      },
      lineX: [],
      lineData: [],

      // 产品销售图表
      chartType: 1,
      barY1: [],
      barData1: [],
      barY2: [],
      barData2: [],
      barY3: [],
      barData3: [],
      barY4: [],
      barData4: [],

      // 销售产品变化
      tableData1: [],
      tableData2: [],
      // total1:0,
      // total2:0,      
      timeRange1: [],
      timeRange2:[],
      total1:0,
      total2:0,
      diff1:{
        page:1,
        size:5,
        type:1,
        start_time:0,
        end_time:0
      },
      diff2:{
        page:1,
        size:5,
        type:2,
        start_time:0,
        end_time:0
      }
    };
  },
  
  created() {
    this.getTotalSales();
    this.getProductSales();
  },

  methods: {
    // type
    getDate(val) {
      this.line.type = val;
      this.getTotalSales();
    },
    // range
    dateRange(val) {
      this.line.start_time = val[0];
      this.line.end_time = val[1];
      this.getTotalSales();
    },
    // 销售总额
    getTotalSales() {
      this.$http
        .getTotalSales({
          type: this.line.type,
          start_time: this.line.start_time,
          end_time: this.line.end_time
        })
        .then(res => {
          this.lineX = res.data.date;
          this.lineData = res.data.amount;
        });
    },

    // 產品圖表
    getProductSales() {
      this.$http.getProductSales().then(res => {
        this.barY1 = res.data.goods_price_ranking.name;
        this.barData1 = res.data.goods_price_ranking.ranking;
        this.barY2 = res.data.goods_receive_ranking.name;
        this.barData2 = res.data.goods_receive_ranking.ranking;
        this.barY3 = res.data.category_price_ranking.name;
        this.barData3 = res.data.category_price_ranking.ranking;
        this.barY4 = res.data.restaurant_amount_ranking.name;
        this.barData4 = res.data.restaurant_amount_ranking.ranking;
      });
    },


    // 時間範圍
    timeRangeFunc1(val) {
      this.diff1.start_time = (val[0].getTime() + "").substring(0, 10);
      this.diff1.end_time = (val[1].getTime() + "").substring(0, 10);
      this.getPriceDiff();
    },
    timeRangeFunc2(val) {
      this.diff2.start_time = (val[0].getTime() + "").substring(0, 10);
      this.diff2.end_time = (val[1].getTime() + "").substring(0, 10);
      this.getNumDiff();
    },
    // 產品金額變化
    getPriceDiff() {
      this.$http
        .getProductDiff({
          ...this.diff1,
        })
        .then(res => {
          console.log('111==>',res.data);
          this.tableData1 = res.data.list;
          this.total1 = res.data.sum;
        });
    },

    // 產品數量變化
    getNumDiff() {
      this.$http
        .getProductDiff({
          ...this.diff2,
        })
        .then(res => {
          this.tableData2 = res.data.list;
          this.total2 = res.data.sum;
        });
    },
    handleCurrentChange1(val){
      this.diff1.page = val;
    },
    handleCurrentChange2(val){
      this.diff2.page = val;
    }

  }
};
</script>

<style lang="scss" scoped></style>
